div#root {
  height: 100%;
  width: 100%;
}

div#root > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.container input {
  margin-bottom: 15px;
}
.container input:focus {
  box-shadow: none;
  border-color: rgb(255, 178, 164);
}
.btn-login {
  background-color: #f68043;
  color: white;
  border-color: #e6763e;
  font-weight: 500;
  text-align: center;
}

.btn-login:focus {
  box-shadow: none;
}

.btn-login:hover {
  background-color: #e0733c;
  /* color:white; */
}
.accordion {
  overflow: auto;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
}
.btn-works {
  text-align: left;
  font-weight: 600;
}
.btn:focus {
  box-shadow: none;
}

.accordion .card {
  background-color: #f8f8f8;
}
.card-body {
  background-color: white;
  padding: 0.25rem;
}
.work-item,
.work-item:hover {
  text-decoration: none;
  color: #212529;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  margin-bottom: 5px;
  justify-content: flex-start;
}
/* .work-item:visited:active{
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  margin-bottom: 5px;
  justify-content: flex-start;
} */

.work-item div {
  margin-right: 50px;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
@media screen and (max-width: 667px) {
  .description {
    display: none;
  }
  .work-item,
  .work-item:hover {
    grid-template-columns: auto auto;
  }
}

.work-form {
  padding: 10px;
  height: 90%;
  width: 100%;
}

.work-form textarea:focus {
  box-shadow: none;
  border-color: rgb(255, 178, 164);
}
.work-form input:focus {
  box-shadow: none;
  border-color: rgb(255, 178, 164);
}

.input_warning .select-search__input {
  border: none;
}

.list-group-item.active {
  z-index: 1;
  background-color: #f68043;
  border: none;
}
.items-characters-work {
  cursor: pointer;
  padding: 6px;
  white-space: pre;
}
.work-form > div {
  margin-bottom: 10px;
}

.work-time {
  display: grid;
  position: relative;
  grid-template-columns: 70px 0.5fr;
  align-items: center;
  justify-content: flex-start;
}
.work-time input {
  margin: 5px;
}
input:checked:after {
  background: #f68043;
}
.work-form select:focus {
  box-shadow: none;
  border-color: rgb(255, 178, 164);
}
.date_input {
  color: black;
  border-radius: 0.2rem;
  border: 1px solid #ccc7c0;
}

.date_input:focus {
  outline: none;
  border: 1px solid #b6b1aa;
}

.card-header .btn {
  width: 100%;
  height: 100%;
}
/* @media screen and (min-width: 760px){
  .btn{
      text-align: center;
  }
} */
.circle-button {
  border: none;
  position: fixed;
  bottom: 50px;
  right: 25px;
  border-radius: 50%;
  box-shadow: none;
  border-color: transparent;
  cursor: pointer;
  background-color: #f68043;
  height: 50px;
  width: 50px;
  z-index: 99;
  color: white;
  text-align: center;
  font-weight: 900;
}
.circle-button:focus {
  outline: none;
}
.icon-plus {
  text-align: center;
}
.menu-list-group {
  height: 100%;
  width: 100%;
  text-align: center;
}
.list-group-item i {
  display: block;
}

.list-group-item:nth-child(n) {
  cursor: pointer;
}
.task-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
}
.task-grid > div {
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 15px;
  font-weight: 500;
  border: 0.5px solid rgb(238, 238, 238);
}

button.dropdown-toggle {
  border: 1px solid #ced4da;
  background-color: white;
}

.dropdown-item.active {
  background-color: #f68043;
}

.error-label {
  width: 100%;
  margin-top: 0;
  color: red;
}
.input_warning {
  border-radius: 0.25rem;
  box-shadow: none;
  outline: none;
  border: 1px solid rgb(255, 93, 93);
}

.arrive-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  text-align: center;
}

.arrive-logos {
  height: 50px;
}
.status-page {
  /* height: 100%; */
  max-width: 1200px;
  margin: auto;
  overflow: auto;
}
@media only screen and (max-width: 960px) {
  .status-page {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  .status-page-card {
    display: none;
  }
}

.paggination-layout {
  position: fixed;
  bottom: 0;
  z-index: 30;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-top: 0.1rem solid gainsboro;
}
/* .btn-pagg { */
/* background-color: #f68043; */
/* color: white; */
/* border-color: #e6763e; */
/* font-weight: 500; */
/* text-align: center; */
/* } */

.btn-light:focus {
  box-shadow: none;
}

/* .btn-pagg:hover {
  background-color: #e0733c;
  font-weight: 500;
  /* color:white; */
/* } */
.btn-pagg:active {
  background-color: #6d6d6d;
  border-color: #6d6d6d;
  font-weight: 500;
  /* color:white; */
}
*/ .no-works {
  text-align: center;
  margin: auto;
}
.header {
  width: 100%;
  background-color: wheat;
  /* display: flex;
  text-align: center;
  justify-content: center; */
}
.back-svg {
  width: 20px;
  height: 100%;
  line-height: 100%;
  /* display: inline-block; */
  position: relative;
  /* border-right:2px solid #ffffff */
}
.logout-svg {
  width: 40px;
  height: 100%;
  line-height: 100%;
  /* display: inline-block; */
  position: relative;
  /* border-right:2px solid #ffffff */
}
.go-back {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 100%;
  border-right: 1px solid #c4c4c4;
  border-radius: 5px;
  position: absolute;
  cursor: pointer;
}
.go-back:active {
  color: #cfcfcf;
  /* border-right:2px solid #ffffff */
}
.go-back:hover {
  background-color: #dd723c;
  /* border-right:2px solid #ffffff */
}
.logout:active {
  color: #cfcfcf;
  /* border-right:2px solid #ffffff */
}
.logout:hover {
  background-color: #dd723c;
  /* border-right:2px solid #ffffff */
}
.logout {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 100%;
  border-right: 1px solid #c4c4c4;
  border-radius: 5px;
  position: absolute;
  cursor: pointer;
  right: 0;
  z-index: 99;
}
.header-title {
  width: 100%;
  padding: 0.75rem 1.25rem;
  text-align: center;
}
.header {
  z-index: 1;
  min-height: 50px;
  background-color: #f68043;
  border: none;
  display: flex;
  align-items: center;
  color: #fff;
  position: relative;
  width: 100%;
}

.cancel-modal {
  background-color: #f64343;
  border-color: #f64343;
}
.cancel-modal:hover {
  background-color: #cc3737;
  border-color: #cc3737;
}
.cancel-modal:focus {
  outline: none;
}
.cancel-modal:active {
  background-color: #bb3030;
  outline: none;
}

.not-full-work {
  background-color: #ff9b9b !important;
}
.errorones {
  color: red;
  font-weight: 700;
}
.popup-edit {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  left: 10%;
  top: 50%;
  padding: 5px;
  border-radius: 0.6rem;
  box-shadow: 2px 2px 3px rgb(138, 138, 138);
}
.popup-edit:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 25%;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.popup-edit:before {
  content: "";
  position: absolute;
  top: -11px;
  left: 25%;
  border-bottom: 10px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.error-form-tip {
  position: relative;
}

.date-time-picker {
  z-index: 3;
}
.date-time-picker2 {
  z-index: 2;
}
/* .react-datepicker-wrapper{
  width: 100%;
} */
.works-loading{
  width: 100%;
  height: 100%;
  padding: 20px;
}